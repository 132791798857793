/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class AppService {
    private envChanged = new Subject<any>();
    envChanged$ = this.envChanged.asObservable();

    changeEnv(env){
        if(env){
            localStorage.setItem('ENV',env);
        }else{
            localStorage.removeItem('ENV');
        }
        this.envChangedEvent();
    }

    envChangedEvent(){
        this.envChanged.next(localStorage.getItem('ENV') ? localStorage.getItem('ENV') : null);
    }
}
