export enum DocumentType {
    CV_CANDIDAT = 1,
    PASS_SANITAIRE = 2,
    CONTRAT_TRAVAIL = 3,
    DEVIS_CLIENT = 4,
    FEUILLEPAIE = 6,
    FACTURE = 7,
    AUTRES = 8,
    CARTEVITAL = 9,
    ARRETMALADIE = 10,
    NoteDeFrais = 11,
    IDCARD = 13,
    RIB = 12,
    SEPA = 14
}
