import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class TokenAuthService {

  private tokenIssuer = {
    login: environment.urlApi + '/api/auth/signin',
    register: environment.urlApi + '/api/auth/signup',
  };
  private tokenIssuerUser = {
    login: environment.urlApi + '/api/auth/signin-user',
    register: environment.urlApi + '/api/auth/signup',
  };

  /**
   *
   * @param document
   */
  constructor(@Inject(DOCUMENT) private document: Document, private appService: AppService) {
    let url = environment.urlApi;

    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      url = environment.urlApiImageProd;
      this.tokenIssuer.login = url + '/api/auth/signin';
      this.tokenIssuer.register = url + '/api/auth/signup';
      this.tokenIssuerUser.login = url + '/api/auth/signin-user';
      this.tokenIssuerUser.register = url + '/api/auth/signup';
    }

    this.appService.envChanged$.subscribe((resp) => {
       // Si on est en TEST => API ImageProd
      if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
        url = environment.urlApiImageProd;
      }else{
        url = environment.urlApi;
      }
      this.tokenIssuer.login = url + '/api/auth/signin';
      this.tokenIssuer.register = url + '/api/auth/signup';
      this.tokenIssuerUser.login = url + '/api/auth/signin-user';
      this.tokenIssuerUser.register = url + '/api/auth/signup';
    });

    // Si on est sur le localhost:4200, on ne fait pas appel à l'api qui est sur la même url
    // mais à l'url de l'api qui est dans environment.ts
    if (document.location.hostname !== 'localhost') {
      this.tokenIssuer.login = url + '/api/auth/signin';
      this.tokenIssuer.register = url + '/api/auth/signup';
      this.tokenIssuerUser.login = url + '/api/auth/signin-user';
      this.tokenIssuerUser.register = url + '/api/auth/signup';
    }
  }

  /**
   * Set la valeur du token dans le localStorage
   * @param token
   */
  setTokenStorage(token) {
    console.log('token', token);
    if (token !== undefined) localStorage.setItem('auth_token', token);
  }

  /**
   * Retourne le token depuis le localStorage
   * @returns
   */
  getJwtToken() {
    if (localStorage.getItem('auth_token') !== 'undefined') {
      return localStorage.getItem('auth_token');
    }
    this.destroyToken();
    return false;
  }

  /**
   * Check la validité du token
   * @returns bool
   */
  validateToken() {
    const token = this.getJwtToken();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        // On check si le payload du token est valide
        const validToken =
          Object.values(this.tokenIssuer).indexOf(payload.iss) > -1 ||
          Object.values(this.tokenIssuerUser).indexOf(payload.iss) > -1;
        if (!validToken) {
          return false;
        }
        // On check si le token a expiré
        const tokenExpired = this.tokenExpired(token);
        if (tokenExpired) {
          return false;
        }
        return true;
      }
    } else {
      return false;
    }
  }

  /**
   * Analyse dy payload du token
   * @param token
   * @returns
   */
  payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  /**
   * Check if token is expired
   * @param token
   * @returns bool
   */
  tokenExpired(token: string) {
    if(token){
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    }
    return true;
  }

  /**
   * Check if token is valid
   * @returns bool
   */
  isSignedin() {
    return this.validateToken();
  }

  /**
   * Destroy token
   */
  destroyToken() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userInfos');
  }
}
