/* eslint-disable max-len */
import { catchError, finalize, tap } from 'rxjs/internal/operators';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TokenAuthService } from '../services/token-auth/token-auth.service';
import { AuthenticationStateService } from '../services/authentication-state/authentication-state.service';
import { JwtService } from '../services/jwt/jwt.service';

@Injectable()
export class HttpError implements HttpInterceptor {
  constructor(
    public router: Router,
    private tokenAuthService: TokenAuthService,
    public authenticationStateService: AuthenticationStateService,
    private jwtService: JwtService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((data) => {
        // console.log('DATA HTTP RESPONSE :', data);
      }),
      catchError((error: HttpErrorResponse) => {
        // const rollbar = this.injector.get(RollbarService);
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`
          );

          // Si statut Unauthenticated
          // if (error.status == 401) {
          if ([401].includes(error.status) && this.authenticationStateService.userValue && this.tokenAuthService.tokenExpired(this.tokenAuthService.getJwtToken() as string)) {
            this.jwtService.refreshToken({}).subscribe(
                (res) => {
                    console.log('resp',res);
                    this.tokenAuthService.setTokenStorage(res.access_token);
                    // this.authenticationStateService.setUserLogged(res.user);
                    // this.setInfosUser();
                },
                (errorRefresh) => {
                    // this.err = error;
                    console.log('Error', errorRefresh);
                },
                () => {
                    this.authenticationStateService.setAuthState(true);
                    // this.signinForm.reset();
                    // this.nav.navigateForward('/account');
                }
                );
          }
        }
        return throwError(error.error);
      }),
      finalize(() =>
        // hide loading spinner
        // this.loadingDialogService.hideDialog();
         false
      )
    );
  }
}
