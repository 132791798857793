import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CryptoService } from '../crypto/crypto.service';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

export class User {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  urlApi = environment.urlApi;

  constructor(
    private http: HttpClient,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
    private crypto: CryptoService
  ) {
    const url = document.location.protocol + '//' + document.location.hostname;
    // Si on est sur le localhost:4200, on ne fait pas appel à l'api qui est sur la même url
    // mais à l'url de l'api qui est dans environment.ts
    // if (document.location.hostname !== 'localhost') {
    //   this.urlApi = url;
    // }

    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }

    this.appService.envChanged$.subscribe((resp) => {
       // Si on est en TEST => API ImageProd
      if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
        this.urlApi = environment.urlApiImageProd;
      }else{
        this.urlApi = environment.urlApi;
      }
    });
  }

  signUp(user: User): Observable<any> {
    return this.http.post(this.urlApi + '/api/auth/signup', user);
  }

  logIn(user: User): Observable<any> {
    // user.password = this.crypto.encrypt(user.password);
    return this.http.post<any>(this.urlApi + '/api/auth/signin', user);
  }
  logInUser(user: User): Observable<any> {
    // user.password = this.crypto.encrypt(user.password);
    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }else{
      this.urlApi = environment.urlApi;
    }
    console.log('this.urlApi',this.urlApi)
    return this.http.post<any>(this.urlApi + '/api/auth/signin-user', user);
  }
  profile(): Observable<any> {
    return this.http.get(this.urlApi + '/api/auth/user');
  }
  refreshToken(datas): Observable<any> {
    return this.http.post(this.urlApi + '/api/auth/token-refresh', datas);
  }
}
