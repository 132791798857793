/* eslint-disable @typescript-eslint/naming-convention */
import { UserModel } from './UserModel';
import { UserRole } from './UserRole';

export class User implements UserModel {
  public id: number;
  public name: string;
  public first_name: string;
  public email: string;
  public created_at: string;
  public profile_photo_path: string;
  public profile_photo_path_64: string;
  public user_role_id: UserRole;
  public roles_ids: number[];

  constructor() {
    this.id = null;
    this.name = '';
    this.email = '';
    this.created_at = '';
    this.profile_photo_path = '';
    this.profile_photo_path_64 = '';
    this.user_role_id = null;
    this.roles_ids = [];
  }
  // getFullName(): string {
  //     return `${this.firstName} ${this.lastName}`
  // }
}
