/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BackgroundGeolocation, BackgroundGeolocationAuthorizationStatus, BackgroundGeolocationConfig, BackgroundGeolocationEvents, BackgroundGeolocationLogLevel, BackgroundGeolocationResponse } from '@awesome-cordova-plugins/background-geolocation/ngx';
import { NavController, Platform, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { AppService } from '../app.service';
import { AuthenticationStateService } from '../authentication-state/authentication-state.service';
moment.locale('fr');

@Injectable({
  providedIn: 'root'
})
export class BackgroundGeolocationService {

  metersArrivedAtClient = environment.metersArrivedAtClient;
  metersLeftFromClient = environment.metersLeftFromClient;

  checkStatutAgentForAppliSub: Subscription;
  telepointageFinMissionSub: Subscription;
  pointageDebutSub: Subscription;

  // Background Geolocation State
  deviceInfo: any;
  state: any;
  enabled: boolean;
  isMoving: boolean;
  distanceFilter: number;
  stopTimeout: number;
  autoSync: boolean;
  stopOnTerminate: boolean;
  startOnBoot: boolean;
  debug: boolean;
  provider: any;

  configBackgroundGeolocInit = false;
  configBackgroundGeoloc: BackgroundGeolocationConfig = {
    url: environment.urlSocket+'/locations',
    syncUrl: environment.urlSocket+'/locations',
    notificationsEnabled: true,
    notificationTitle: 'Wanteez',
    notificationText: 'Vos missions du jour',
    notificationIconColor: '#c84f94',
    // httpHeaders: {
    //   'Content-Type': 'application/json'
    // },
    postTemplate: {
      lat: '@latitude',
      lng: '@longitude',
      timestamp: '@time',
      agentId: this.authenticationStateService.userValue?.candidat_id, // you can also add your own properties
      platform: this.plt.is('ios') ? 'ios' : 'android'
    },
    // postTemplate: ['@latitude','@longitude'],
    // locationProvider: BackgroundGeolocationLocationProvider.ACTIVITY_PROVIDER,
    desiredAccuracy: 5,
    stationaryRadius: 5,
    // distanceFilter: 5,
    distanceFilter: 10,
    debug: environment.showLogs, //  enable this hear sounds for background-geolocation life-cycle.
    stopOnTerminate: false, // enable this to clear background location settings when the app terminates
    startForeground: true,
    interval: 5000, //El intervalo en el que se comprueba la localización.
    // fastestInterval: 5000, //Este para cuando está en movimiento.
    // activitiesInterval: 10000, //Este es para cuando está realizando alguna actividad con el dispositivo.
  };

  logs;
  locations;
  locationMarkers = [];

  intervention;

  goToClient = false;
  isAtClient = false;
  interventionAVenir;
  interventionIn30Minutes = null;

  telepointageDebutEnCours = false;
  telepointageFinEnCours = false;

  constructor(
    public backgroundGeolocation: BackgroundGeolocation,
    private authenticationStateService: AuthenticationStateService,
    private toastCtrl: ToastController,
    private shared: SharedService,
    private nav: NavController,
    private api: ApiService,
    private plt: Platform,
    private appService: AppService
  ) {

    this.initConfig();

    console.log('JE PASSE LA 1');
    if(!this.configBackgroundGeolocInit){
      console.log('JE PASSE LA 2');
      this.authenticationStateService.setAuthState(true);
    }
    this.plt.ready().then(() => {
      if(authenticationStateService.check()){
        this.checkStatutAgent();
        this.checkStatus();
      }

      // setTimeout(() => {
      //   console.log('BGS - TIMEOUT !!');
      //   this.backgroundGeolocation.getCurrentLocation().then((resp) => {
      //     const newPosition = {
      //       lat: parseFloat(resp.latitude.toString()),
      //       lng: parseFloat(resp.longitude.toString())
      //     };
      //     const locationChanged = this.shared.lat_actuelle !== parseFloat(resp.latitude.toString()) && this.shared.lng_actuelle !== parseFloat(resp.longitude.toString());
      //     if(locationChanged){
      //       this.actionToDo(newPosition);
      //     }
      //   });
      // }, this.configBackgroundGeoloc.interval);

    });
  }

  async initConfig(){

    if(this.plt.is('android')){
      this.configBackgroundGeoloc.httpHeaders = {
        'Content-Type': 'application/json'
      };
    }

    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.configBackgroundGeoloc.url = environment.urlSocketImageProd+'/locations';
      this.configBackgroundGeoloc.syncUrl = environment.urlSocketImageProd+'/locations';
    }

    this.appService.envChanged$.subscribe((resp) => {
       // Si on est en TEST => API ImageProd
      if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
        this.configBackgroundGeoloc.url = environment.urlSocketImageProd+'/locations';
        this.configBackgroundGeoloc.syncUrl = environment.urlSocketImageProd+'/locations';
      }else{
        this.configBackgroundGeoloc.url = environment.urlSocket+'/locations';
        this.configBackgroundGeoloc.syncUrl = environment.urlSocket+'/locations';
      }
    });

    this.configBackgroundGeoloc.postTemplate.agentId = this.authenticationStateService.userValue?.candidat_id;
    this.configBackgroundGeoloc.postTemplate.interventionId = this.intervention?.id;
    this.configBackgroundGeoloc.postTemplate.platform = this.plt.is('ios') ? 'ios' : 'android';

    this.backgroundGeolocation.on(BackgroundGeolocationEvents.start)
    .subscribe((location: BackgroundGeolocationResponse) => {
      console.log('BGS - Start Service');
      // this.toast('Géolocalisation démarrée...');
    });
    this.backgroundGeolocation.on(BackgroundGeolocationEvents.stop)
    .subscribe((location: BackgroundGeolocationResponse) => {
      console.log('BGS - Stop Service');
      this.toast('Géolocalisation arrêtée...');
    });
    this.backgroundGeolocation.on(BackgroundGeolocationEvents.authorization)
    .subscribe((status: any) => {
      console.log('[INFO] BackgroundGeolocation authorization status: ' + status);
      if (status !== BackgroundGeolocationAuthorizationStatus.AUTHORIZED) {
        // we need to set delay or otherwise alert may not be shown
        setTimeout(() => {
          if (confirm('Veuillez activer la localisation pour l\'application Wanteez dans les paramètres')) {
            return this.backgroundGeolocation.showAppSettings();
          }
        }, 1000);
      }
    });

    await this.configureOptions(this.configBackgroundGeoloc);
    const config = await this.getConfig();
    console.log('CONFIG ICI : ' + (config));
  }

  // On check si la géoloc background est en cours
  // Si non, on la lance
  async checkStatus(){
    console.log('BGS - checkStatus');
    const isRunning = await this.checkStatusGeolocService();
    console.log('BGS - isRunning : ',isRunning);
    if(!isRunning){
      // this.stopBackgroundGeolocation();
      // this.startBackgroundGeolocation(true);
    }else{
      // this.stopBackgroundGeolocation();
      // this.startBackgroundGeolocation();
    }
    // const havePermissionLocation = await this.checkPermissionGeolocService();
    // if(!havePermissionLocation){
    //   this.backgroundGeolocation.showAppSettings();
    // }
  }

  startBackgroundGeolocation(isMoving = true) {
    console.log('BGS - startBackgroundGeolocation');
    this.backgroundGeolocation.start();
    this.backgroundGeolocation.changePace(isMoving);
    this.state = true;
  }

  startParcoursToClient(toInterventionId){
    console.log('BGS - startParcoursToClient');
    this.backgroundGeolocation.getCurrentLocation().then((resp) => {
      localStorage.setItem('parcoursStart', JSON.stringify({
        lat: resp.latitude,
        lng: resp.longitude,
        interventionId: toInterventionId,
        start: moment()
      }));
    });
    this.goToClient = true;
    this.configBackgroundGeoloc.postTemplate.goToClient = true;
    this.backgroundGeolocation.setConfig(this.configBackgroundGeoloc);
  }

  stopBackgroundGeolocation() {
    console.log('BGS - stopBackgroundGeolocation');
    // If you wish to turn OFF background-tracking, call the #stop method.
    this.backgroundGeolocation.stop();
    this.backgroundGeolocation.finish();
    this.state = false;
  }

  stopParcoursToClient(){
    setTimeout(() => {
      this.nav.navigateForward('/profil/mission-cours/'+this.intervention?.id+'/mission-arrived');
    }, 1000);
    this.toast('VOUS ETES ARRIVES CHEZ LE CLIENT !');
    console.log('BGS - VOUS ETES ARRIVES CHEZ LE CLIENT !');
    this.goToClient = false;
    this.isAtClient = true;
    localStorage.setItem('isAtClient', this.isAtClient ? '1' : '0');
    this.configBackgroundGeoloc.postTemplate.goToClient = false;
    this.backgroundGeolocation.setConfig(this.configBackgroundGeoloc);
  }

  async doSync() {
    console.log('BGS - doSync');
    // await this.getLogs();
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.forceSync().then((records) => {
        console.log(`BGS - Synced ${records.length} records to server.`);
        console.log('BGS - #sync success: ', records.length);
        resolve(records.length);
      });
    });
  }
  checkStatusGeolocService(){
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.checkStatus().then((status) => {
        if(status.isRunning){
          // this.toast('BackgroundGeolocation service is running');
          this.state = true;
        }else{
          this.state = false;
        }
        resolve(status.isRunning);
      });
    });
  }

  checkPermissionGeolocService(){
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.checkStatus().then((status) => {
        console.log('BGS - checkPermissionGeolocService RETURN',status);
        console.log('BGS - checkPermissionGeolocService RETURN',JSON.stringify(status));

        if(status.authorization === BackgroundGeolocationAuthorizationStatus.AUTHORIZED){
          resolve(true);
        }else{
          this.toast('Veuillez activer la localisation pour l\'application Wanteez dans les paramètres');
          setTimeout(() => {
            this.backgroundGeolocation.showAppSettings();
          }, 1500);
          resolve(false);
        }
      });
    });
  }

  getConfig(){
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.getConfig().then((config) => {
        console.log('BGS - ICI - CONFIG RETURN',JSON.stringify(config));
        resolve(config);
      });
    });
  }

  configureOptions(options){
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.configure(options).then((success) => {
        console.log('BGS - ICI - NEW CONFIG',JSON.stringify(success));
        resolve(success);
      });
    });
  }

  getLocations() {
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.getLocations().then((locations) => {
        if (locations.length > 0) {
          resolve(locations);
        } else {
          console.error('Database is empty');
          // this.toast('Database is empty');
        }
      });
    });
  }

  hasLocations() {
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.getLocations().then((locations) => {
        if (locations.length > 0) {
          resolve(locations.length);
        } else {
          console.error('Database is empty');
        }
      });
    });
  }

  async getLogEntries() {
    return new Promise((resolve, reject) => {
      this.backgroundGeolocation.getLogEntries(100, 0, BackgroundGeolocationLogLevel.TRACE).then((logs) => {
        resolve(logs);
      });
    });
  }

  async getLogs(){
    this.locations = await this.getLocations();
  }

  checkIfArrivedAtClient(newPosition){
    // Si l'intervention est déjà en cours, on ne fait pas le pointage de début
    // if(localStorage.getItem('interventionEnCours')){
    //   this.isAtClient = true;
    //   return;
    // }
    if(this.intervention === null || !this.intervention){
      return;
    }
    console.log('newPosition',newPosition);

    const clientPosition = {lat: this.intervention.lat, lng: this.intervention.lng};
    const dateDebutInter = moment(this.intervention.start);
    const dateActuelle = moment();
    console.log('BGS - dateDebutInter',dateDebutInter.format('DD/MM/YYYY HH:mm'));
    console.log('BGS - dateActuelle',dateActuelle.format('DD/MM/YYYY HH:mm'));
    const dateActualDepasseHeureDebutInter = dateActuelle.diff(dateDebutInter, 'minutes') >= 0;
    console.log('BGS - dépassé temps début',dateActualDepasseHeureDebutInter);

    const entreeCercleClient = this.entreeCercleLocation(newPosition, clientPosition, this.metersArrivedAtClient/1000);
    console.log('entreeCercleClient',entreeCercleClient);

    // Check si client 20m autour
    if(entreeCercleClient && dateActualDepasseHeureDebutInter){
      // setTimeout(() => {
      //   this.nav.navigateForward('/profil/mission-cours/'+this.intervention?.id+'/mission-arrived');
      // }, 1000);
      this.toast('VOUS ETES ARRIVES CHEZ LE CLIENT !');
      console.log('BGS - VOUS ETES ARRIVES CHEZ LE CLIENT !');
      this.goToClient = false;
      this._pointageDebut(true);
    }else{
      console.log('BGS - PAS ENCORE CHEZ LE CLIENT !');
    }
  }

  entreeCercleLocation(checkPoint, centerPoint, km) {
    const ky = 40000 / 360;
    const kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    console.log('DISTANCE AVEC LE CLIENT : ' + Math.sqrt(dx * dx + dy * dy));
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  checkIfParti(newPosition){
    if(this.intervention === null || !this.intervention){
      return;
    }
    const clientPosition = {lat: this.intervention.lat, lng: this.intervention.lng};
    // Check si l'agent dépasse les 100m autour du client et que l'heure de fin d'intervention est dépassé
    const dateFinInter = moment(this.intervention.end);
    const dateActuelle = moment();
    console.log('BGS - dateFinInter',dateFinInter.format('DD/MM/YYYY HH:mm'));
    console.log('BGS - dateActuelle',dateActuelle.format('DD/MM/YYYY HH:mm'));
    const dateActualDepasseHeureFinInter = dateActuelle.diff(dateFinInter, 'minutes') >= 0;
    console.log('BGS - dépassé temps fin',dateActualDepasseHeureFinInter);
    const sortiCercleClient = this.sortiCercleLocation(newPosition, clientPosition, this.metersArrivedAtClient/1000);
    console.log('sortiCercleClient',sortiCercleClient);
    if(sortiCercleClient && dateActualDepasseHeureFinInter){
      this.toast('VOUS PARTEZ DE CHEZ LE CLIENT !');
      console.log('BGS - VOUS PARTEZ DE CHEZ LE CLIENT !');
      this._telepointageFinMission(true);

      // Si on a une intervention dans les 30 minutes après celle-ci, on commence de tracer son parcours pour l'intervacation
      if(this.interventionIn30Minutes){
        this.startParcoursToClient(this.interventionIn30Minutes.id);
      }

      this.isAtClient = false;
      localStorage.setItem('isAtClient', '0');
    }
  }

  sortiCercleLocation(checkPoint, centerPoint, km) {
    const ky = 40000 / 360;
    const kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    console.log('BGS - DISTANCE AVEC LE CLIENT ?? : ' + Math.sqrt(dx * dx + dy * dy));
    return Math.sqrt(dx * dx + dy * dy) > km;
  }

  actionToDo(newPosition){
    // if(this.isAtClient){
    if(localStorage.getItem('isAtClient') === '1'){
      // Attente télépointage fin auto. quand on part de chez le client
      console.log('BGS - _actionToDo : checkIfParti');
      if(newPosition){
        this.checkIfParti(newPosition);
      }

      // // On fait un check en plus pour savoir si on est pas déjà chez le client suivant après l'heure de fin de la première
      // // pour les cas où 2 clients qui se suivent dans l'horaire sont géographiquement voisins
      // const dateFinInter = moment.utc(this.intervention.date_fin);
      // const dateActuelle = moment();
      // if(this.isAtClient && dateFinInter.diff(dateActuelle, 'minutes') > 0){
      //   this._checkIfAtClientSuivant(newPosition);
      // }
    // }else if(this.interventionAVenir){
    }else if(localStorage.getItem('interventionAVenir')){

      // Attente télépointage début auto. quand on arrive chez le client
      console.log('BGS - _actionToDo : checkIfArrivedAtClient');
      if(newPosition){
        this.checkIfArrivedAtClient(newPosition);
      }
    }else{
      // Sinon, on arrête la géolocalisation background
      console.log('BGS - _actionToDo : stopBackgroundGeolocation');
      // this.stopBackgroundGeolocation();
    }
  }

  checkStatutAgent(newPosition = null){
    console.log('BGS - _checkStatutAgent');
    const datas = {
      agentId: this.authenticationStateService.userValue?.candidat_id
    };
    this.checkStatutAgentForAppliSub = this.api.checkStatutAgentForAppli(datas).subscribe((resp) => {
      if(resp && resp?.success){
        console.log('BGS - _checkStatutAgent RESPONSE', resp);
        this.interventionAVenir = resp.interventionAVenir;
        localStorage.setItem('interventionAVenir', this.interventionAVenir);
        this.interventionIn30Minutes = resp.interventionIn30Minutes;
        // if(resp.interventionEnCours){
        //   this.isAtClient = true;
        // }
        // this.intervention = resp.interventionEnCours;
        if(!resp.interventionEnCours){
          this.intervention = resp.interventionAVenir;
        }
        console.log('_checkStatutAgent : intervention',this.intervention);
        if(!resp.interventionEnCours && !resp.interventionAVenir){
          // this.stopBackgroundGeolocation();
        }
        // this.actionToDo(newPosition);
      }
    });
  }

  private async toast(message, duration?) {
    (await this.toastCtrl.create({
      message,
      cssClass: 'toast',
      duration: duration || 3000
    })).present();
  }

  private _logsAgentDb(){
    // TODO: logguer différentes infos dans BDD MongoDB
  }

  private async _telepointageFinMission(isPointageGeolocAuto = false){
    if(this.telepointageFinEnCours){
      return;
    }
    this.telepointageFinEnCours = true;
    console.log('BGS - TELEPOINTAGE DE FIN...');
    const commentairesAgent = this.shared.missionCommentaires;
    const havePermissionLocation = await this.checkPermissionGeolocService();
    const datas = { interventionId: this.intervention?.id, commentairesAgent, isPointageGeolocAuto, havePermissionLocation };
    this.telepointageFinMissionSub = this.api.pointageFinIntervention(datas).subscribe((resp) => {
      if(resp && resp?.success){
        const interventionEnCours = localStorage.getItem('interventionEnCours');
        if(interventionEnCours && interventionEnCours === this.intervention.id){
          localStorage.removeItem('interventionEnCours');
        }
        this.shared.mission_signed = true;
        this.nav.navigateForward('/account');
        this.toast('Télépointage de fin effectué...');
        this.isAtClient = false;
        localStorage.setItem('isAtClient', '0');
        this.telepointageFinEnCours = false;
        this.checkStatutAgent();
      }else{
        this.toast('Erreur lors du télépointage de fin. Veuillez contacter notre équipe.');
      }
    });
  }

  // private _checkIfAtClientSuivant(newPosition) {
  //   console.log('BGS - _checkIfAtClientSuivant');
  //   const clientPosition = {lat: this.interventionAVenir.lat, lng: this.interventionAVenir.lng};
  //   // Check si client 20m autour
  //   if(this.entreeCercleLocation(newPosition, clientPosition, this.metersArrivedAtClient/1000)){
  //     this.toast('Vous êtes arrivés chez le client suivant');
  //     console.log('BGS - VOUS ETES ARRIVES CHEZ LE CLIENT SUIVANT !');

  //     // Télépointage mission précédent
  //     this._telepointageFinMission();

  //     // On va sur la page de la nouvelle intervention pour pointage début
  //     setTimeout(() => {
  //       this.nav.navigateForward('/profil/mission-cours/'+this.interventionAVenir?.id+'/mission-arrived');
  //     }, 1000);
  //   }
  // }

  private async _pointageDebut(isPointageGeolocAuto = false){
    console.log('BGS - telepointageDebutEnCours',this.telepointageDebutEnCours);
    // if(this.telepointageDebutEnCours){
    //   return;
    // }
    // Si l'intervention est déjà en cours, on ne fait pas le pointage de début
    // if(localStorage.getItem('interventionEnCours') === this.intervention.id){
    //   this.isAtClient = true;
    //   return;
    // }
    this.telepointageDebutEnCours = true;
    console.log('BGS - TELEPOINTAGE DEBUT...');
    let parcoursStart = localStorage.getItem('parcoursStart') ? JSON.parse(localStorage.getItem('parcoursStart')) : null;
    parcoursStart = { ...parcoursStart, ...{ end: moment() }};
    const havePermissionLocation = await this.checkPermissionGeolocService();
    const datas = { interventionId: this.intervention.id, parcoursStart, isPointageGeolocAuto, havePermissionLocation };
    this.pointageDebutSub = this.api.pointageDebutIntervention(datas).subscribe((resp) => {
      if(resp && resp?.success){
        this.isAtClient = true;
        localStorage.setItem('isAtClient', '1');
        // this.checkStatutAgent();
        localStorage.setItem('interventionEnCours',this.intervention.id);
        localStorage.removeItem('parcoursStart');
        this.telepointageDebutEnCours = false;
      }
    });
  }

}
