import { HttpClient } from '@angular/common/http';
/* eslint-disable max-len */
import { AlertController, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { Component } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationStateService } from './services/authentication-state/authentication-state.service';
import { SharedService } from './shared/shared.service';
import { TokenAuthService } from './services/token-auth/token-auth.service';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { ApiService } from './services/api/api.service';
 import * as moment from 'moment';
 import { environment } from 'src/environments/environment';
import { AppService } from './services/app.service';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { interval, Subscription } from 'rxjs';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Geolocation, Geoposition, PositionError } from '@awesome-cordova-plugins/geolocation/ngx';

import BackgroundGeolocation, {
  Location,
  MotionChangeEvent,
  MotionActivityEvent,
  GeofenceEvent,
  Geofence,
  HttpEvent,
  ConnectivityChangeEvent
} from "cordova-background-geolocation-lt";  
import { BackgroundGeolocationService } from './services/background-geolocation/background-geolocation.service';

 @Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  env;
  bgSubOnLocation: Subscription;
  bgSubOnForeground: Subscription;
  bgSubOnBackground: Subscription;

  loadingIab;

  constructor(
    private statusBar: StatusBar,
    public plt: Platform,
    private authenticationStateService: AuthenticationStateService,
    private toastCtrl: ToastController,
    public shared: SharedService,
    public bgService:BackgroundGeolocationService,
    private tokenAuthService: TokenAuthService,
    private nav: NavController,
    private fcm: FCM,
    private geo: Geolocation,
    private api: ApiService,
    
    private alertController: AlertController,
    // public bgs: BackgroundGeolocationService,
    //  private backgroundGeolocation: BackgroundGeolocation,
    public httpClient: HttpClient,
    private appService: AppService,
    private iab: InAppBrowser,
    private loadingCtrl: LoadingController
    // private diagnosticv: Diagnostic,
    ) {

    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.env = 'TEST';
    }

    plt.ready().then(this.configureBackgroundGeolocation.bind(this));

    this.appService.envChanged$.subscribe((resp) => {
      this.env = resp;
    });

    this.checkIsUserSignedIn();
    this.shared.getNotificationsOfUser();

    // this.authenticationStateService.userAuthState.subscribe(isSignedin => {
    //   if(isSignedin){
    //     console.log('userAuthState subscribe', isSignedin);
    //     console.log('AGENT ID', this.authenticationStateService.userValue?.candidat_id);
    //     this.bgs.configBackgroundGeoloc.postTemplate.agentId = this.authenticationStateService.userValue?.candidat_id; // you can also add your own properties
    //     console.log('this.bgs.configBackgroundGeoloc',JSON.stringify(this.bgs.configBackgroundGeoloc));
    //     // this.subscribeBackgroundGeoloc();
    //   }
    // });

    this.plt.ready().then(() => {
   
      this.bgService.stopBackgroundGeolocation();

      this.bgService.initConfig();
      this.bgService.startBackgroundGeolocation();
      // let functionSaveGpsSUB = interval(900000).subscribe((func => {
       
      //   this.savePositionGps();

      // }));
      let functionSaveGpsSUB = interval(10000).subscribe((func => {
       
        this.savePositionGps();

      }));
      

       
      // let status bar overlay webview
      this.statusBar.overlaysWebView(true);
      // this.locaPro.startLocationTracker();

      this.checkNewNotifs();

      // // Activation service background geolocation
      // if (this.tokenAuthService.isSignedin()) {
      //   console.log('AGENT ID', this.authenticationStateService.userValue?.candidat_id);
      //   this.bgs.configBackgroundGeoloc.postTemplate.agentId = this.authenticationStateService.userValue?.candidat_id; // you can also add your own properties
      //   console.log('this.bgs.configBackgroundGeoloc',JSON.stringify(this.bgs.configBackgroundGeoloc));
      //   // this.subscribeBackgroundGeoloc();
      // }

 
       // Demande permission receive notifications
      this.fcm.hasPermission().then(hasPermission => {
        if (hasPermission) {
          console.log('Has permission!');
        }else{
          console.log('Has NOT permission!');
          this.fcm.requestPushPermission().then((success) => {
            console.log('ASK PERMISSION HERE');
            if (success) {
              console.log('get token');
              this.getFcmToken();
            }
          });
        }
      });

      // NOTIFICATION FIREBASE
      this.fcm.onNotification().subscribe(async data => {
        if (data.wasTapped) {
          console.log('Received in background',data);
          // Si on a une notification en arrière plan, pendant que l'appli est fermée (même pas en arrière plan)...
          // ... On save la dernière notif, pour pouvoir l'afficher
          // TODO: gérer si plusieurs notifs reçues (au moins pour les notifs de proposition de missions)
          localStorage.setItem('newNotif',JSON.stringify(data));
          if(data.landing_page){
            this.nav.navigateForward(data.landing_page);
          }else if(data.link_in_web){
            const browser = this.iab.create(data.link_in_web, '_blank', 'hideurlbar=yes,toolbarcolor=#C84F94,toolbarposition=top,hidespinner=true,zoom=no,hidenavigationbuttons=yes,closebuttoncaption=Fermer,closebuttoncolor=#ffffff');
            browser.on('loadstart').subscribe((ev) => {
              this.showLoadIab();
            });
            browser.on('loadstop').subscribe((ev) => {
              this.hideLoadIab();
            });
            browser.on('loaderror').subscribe((ev) => {
              this.showToast('Erreur lors de l\'envoi de votre réponse');
              this.hideLoadIab();
            });
            browser.on('exit').subscribe((ev) => {
              this.hideLoadIab();
            });
          }
        } else {
          console.log('Received in foreground',data);
          this.shared.getNotificationsOfUser();
          if(data.landing_page || data.link_in_web){
            await this.onReceiveNotificationForeground(data);
          }
        };
      });

      this.fcm.onTokenRefresh().subscribe(token => {
        // Register your new token in your back-end if you want
        // backend.registerToken(token);
        console.log('TOKEN onTokenRefresh', token);
        this.setTokenUser(token);
  
      });

      if (this.tokenAuthService.isSignedin()) {
 
        this.getFcmToken();
        this.subscribeToTopic();
        this.checkIfUserHaveFcmTokenValid();
      }


      // if(this.plt.is('ios') || this.plt.is('android')){
      //   setInterval(() => {
      //     // this.bgs.doSync();
      //     // console.log('BGS - TIMEOUT 2 !!');
      //     // this.backgroundGeolocation.getCurrentLocation().then((resp) => {
      //     //   const newPosition = {
      //     //     lat: parseFloat(resp.latitude.toString()),
      //     //     lng: parseFloat(resp.longitude.toString())
      //     //   };
      //     //   const locationChanged = this.shared.lat_actuelle !== parseFloat(resp.latitude.toString()) && this.shared.lng_actuelle !== parseFloat(resp.longitude.toString());
      //     //   if(locationChanged){
      //     //     this.bgs.actionToDo(newPosition);
      //     //   }
      //     // });
      //   }, this.bgs.configBackgroundGeoloc.interval);
      // }

      // On vérifie qu'on a bien la geoloc d'activé
      // this.checkLocationState();
      // this.diagnosticv.registerLocationStateChangeHandler((state) => {
      //   console.log('Location state changed to : ' + state);
      //   if((this.plt.is('android') && state !== this.diagnosticv.locationMode.LOCATION_OFF)
      //   || (this.plt.is('ios')) && ( state === this.diagnosticv.permissionStatus.GRANTED || state === this.diagnosticv.permissionStatus.GRANTED_WHEN_IN_USE
      //   )){
      //     console.log('Location is available');
      //   }else{
      //     console.log('LOCATION NON DISPO');
      //     this.unsubscribeBackgroundGeoloc();
      //     this.bgs.stopBackgroundGeolocation();
      //     this.backgroundGeolocation.stop();
      //   }
      // });

    });
  }

  // checkLocationState(){
  //   console.log('ICI');
  //   this.diagnosticv.isLocationEnabled().then(function(result) {
  //     console.log('ICI 1 : ', result);
  //     if(result){
  //       this.bgs.startBackgroundGeolocation();
  //     }else{
  //       console.log('ICI 2 : on stope tout');
  //       this.unsubscribeBackgroundGeoloc();
  //       this.bgs.stopBackgroundGeolocation();
  //       this.backgroundGeolocation.stop();
  //     }
  //   });
  // }

  async checkNewNotifs(){
    console.log('NEW NOTIFS LOCAL STORAGE ?? ', localStorage);
    console.log('NEW NOTIFS ?? ', localStorage.getItem('newNotif'));
    if(localStorage.getItem('newNotif')){
      const newNotif = JSON.parse(localStorage.getItem('newNotif'));
      this.shared.getNotificationsOfUser();
      if(newNotif.landing_page || newNotif.link_in_web){
        await this.onReceiveNotificationForeground(newNotif);
      }
    }
  }

  subscribeToTopic() {
    this.fcm.subscribeToTopic('newappversion');
  }
  getFcmToken() {
    this.fcm.getToken().then(token => {
      // Register your new token in your back-end if you want
      // backend.registerToken(token);
      console.log('TOKEN getToken', token);

      // Si le user n'a pas encore de FCM_token, ou si il est différent de cleui actuelle, on mets à jour
      const user: any = typeof this.authenticationStateService.userValue === 'string' ? JSON.parse(this.authenticationStateService.userValue) : this.authenticationStateService.userValue;
      // if(user && (!user?.fcm_token && user?.fcm_token !== user.fcm_token)){
      if(user){
        this.setTokenUser(token);
      }
    });
  }
  unsubscribeFromTopic() {
    this.fcm.unsubscribeFromTopic('newappversion');
  }

  async onReceiveNotificationForeground(data) {
    console.log('notification datas',data);
    const notification = data.notification ? JSON.parse(data.notification) : null;
    if(!notification){
      return;
    }
    const alert = await this.alertController.create({
      header: notification.title,
      message: notification.body,
      buttons: [{
        text: 'Ok',
        handler: () => {
          localStorage.removeItem('newNotif');
          if(data.landing_page){
            this.nav.navigateForward(data.landing_page);
          }else if(data.link_in_web){
            const browser = this.iab.create(data.link_in_web, '_blank', 'hideurlbar=yes,toolbarcolor=#C84F94,toolbarposition=top,hidespinner=true,zoom=no,hidenavigationbuttons=yes,closebuttoncaption=Fermer,closebuttoncolor=#ffffff');
          }
        }
      }],
      cssClass: 'w-alert'
    });
    await alert.present();
  }

  setTokenUser(token) {
    const user: any = typeof this.authenticationStateService.userValue === 'string' ? JSON.parse(this.authenticationStateService.userValue) : this.authenticationStateService.userValue;
    if(user){
      user.fcm_token = token;
      console.log('user_before_save', user);
      this.api.updateUserAccount(user).subscribe((res) => {
        if (res?.success) {
          this.authenticationStateService.setUserLogged(res.datas);
          localStorage.setItem('fcm_token', JSON.stringify({
            token,
            expiredAt: moment().add('1', 'hour')
          }));
        }
      });
    }
  }

  checkIfUserHaveFcmTokenValid(){
    const fcmToken = localStorage.getItem('fcm_token') ? JSON.parse(localStorage.getItem('fcm_token')) : null;
    if(fcmToken){
      if(moment() > moment(fcmToken.expiredAt)){
        this.fcm.getToken().then(token => {
          console.log('TOKEN getToken', token);
          this.setTokenUser(token);
        });
      }
    }else{
      this.fcm.getToken().then(token => {
        console.log('TOKEN getToken', token);
        this.setTokenUser(token);
      });
    }
  }

  checkIsUserSignedIn(){
    if (!this.tokenAuthService.isSignedin()) {
      this.nav.navigateForward('/auth/login');
    }
  }


  getUserPosition() {
    return new Promise((resolve, reject) => {
    let options = {
      maximumAge: 3000,
      enableHighAccuracy: true
    };
   
    this.geo.getCurrentPosition(options).then((pos: Geoposition) => {
     const location = {
       lat: pos.coords.latitude,
       lng: pos.coords.longitude,
       time: new Date(),
     };
    console.log('loc', location);
    resolve(pos);
   }, (err: PositionError) => {
    alert("error : " + err.message)
     console.log("error : " + err.message);
     reject(err.message);
    });
   });
  }

  // unsubscribeBackgroundGeoloc(){
  //   this.bgSubOnLocation.unsubscribe();
  //   this.bgSubOnForeground.unsubscribe();
  //   this.bgSubOnBackground.unsubscribe();
  //   this.bgs.stopBackgroundGeolocation();
  // }

  // subscribeBackgroundGeoloc(){
  //   console.log('BGS - JE SUIS APPELE ???');
  //   this.backgroundGeolocation.configure(this.bgs.configBackgroundGeoloc)
  //   .then(() => {

  //     let newPosition;

  //     this.bgSubOnLocation = this.backgroundGeolocation.on(BackgroundGeolocationEvents.location).subscribe((location: BackgroundGeolocationResponse) => {
  //       console.log('BGS - on location - Nouvelle localisation - ' + JSON.stringify(location));

  //       this.bgs.configBackgroundGeoloc.postTemplate.agentId = this.authenticationStateService.userValue?.candidat_id;
  //       this.bgs.configBackgroundGeoloc.postTemplate.interventionId = this.bgs.intervention.id;
  //       this.backgroundGeolocation.setConfig(this.bgs.configBackgroundGeoloc);

  //       if(environment.showLogs){
  //         this.showToast(JSON.stringify(location));
  //       }
  //       // this.locationMarkers.push({lat: parseFloat(location.latitude.toString()), lng: parseFloat(location.longitude.toString())});

  //       // const locationChanged = this.shared.lat_actuelle !== parseFloat(location.latitude.toString()) && this.shared.lng_actuelle !== parseFloat(location.longitude.toString());
  //       const locationChanged = true;

  //       this.shared.lat_actuelle =  parseFloat(location.latitude.toString());
  //       this.shared.lng_actuelle =  parseFloat(location.longitude.toString());

  //       // this.checkIfArrivedAtClient({lat: parseFloat(location.latitude.toString()), lng: parseFloat(location.longitude.toString())});

  //       newPosition = {
  //         lat: parseFloat(location.latitude.toString()),
  //         lng: parseFloat(location.longitude.toString())
  //       };
  //       if(locationChanged){
  //         // this._checkStatutAgent(newPosition);
  //         this.bgs.actionToDo(newPosition);
  //       }
  //       else if(this.bgs.intervention){
  //         // On fait un check en plus pour savoir si on est pas déjà chez le client suivant après l'heure de fin de la première
  //         // pour les cas où 2 clients qui se suivent dans l'horaire sont géographiquement voisins
  //         // const dateFinInter = moment.utc(this.intervention.date_fin);
  //         // const dateActuelle = moment();
  //         // if(this.isAtClient && dateFinInter.diff(dateActuelle, 'minutes') > 0){
  //         //   this._checkIfAtClientSuivant(newPosition);
  //         // }
  //       }

  //       if(this.plt.is('ios')){
  //         this.backgroundGeolocation.startTask().then((taskKey) => {
  //           console.log('TASK BACKGROUND', location);
  //           this.bgs.actionToDo(newPosition);
  //           this.backgroundGeolocation.endTask(taskKey);
  //         });
  //       }

  //       // IMPORTANT:  You must execute the finish method here to inform the native plugin that you're finished,
  //       // and the background-task may be completed.  You must do this regardless if your operations are successful or not.
  //       // IF YOU DON'T, ios will CRASH YOUR APP for spending too much time in the background.
  //       this.backgroundGeolocation.finish(); // FOR IOS ONLY
  //     });

  //     this.bgSubOnForeground = this.backgroundGeolocation.on(BackgroundGeolocationEvents.foreground)
  //     .subscribe((location: BackgroundGeolocationResponse) => {
  //       console.log('BGS - on foreground - localisation - ' + JSON.stringify(location));
  //       if(this.bgs.state && location){
  //         // this.toast(JSON.stringify('La géoloc revient en premier plan...'));
  //         // this.checkIfArrivedAtClient({lat: parseFloat(location.latitude.toString()), lng: parseFloat(location.longitude.toString())});
  //         newPosition = {
  //           lat: parseFloat(location.latitude.toString()),
  //           lng: parseFloat(location.longitude.toString())
  //         };
  //         const locationChanged = this.shared.lat_actuelle !== parseFloat(location.latitude.toString()) && this.shared.lng_actuelle !== parseFloat(location.longitude.toString());
  //         if(locationChanged){
  //           // this._checkStatutAgent(newPosition);
  //           this.bgs.actionToDo(newPosition);
  //         }
  //       }
  //     });
  //     this.bgSubOnBackground = this.backgroundGeolocation.on(BackgroundGeolocationEvents.background)
  //     .subscribe((location: BackgroundGeolocationResponse) => {
  //       console.log('BGS - on background - localisation - ' + JSON.stringify(location));
  //       if(this.bgs.state && location){
  //         this.showToast('Wanteez continue en arrière-plan...');
  //         newPosition = {
  //           lat: parseFloat(location.latitude.toString()),
  //           lng: parseFloat(location.longitude.toString())
  //         };
  //         // this._checkStatutAgent(newPosition);
  //       }
  //     });
  //     this.backgroundGeolocation.on(BackgroundGeolocationEvents.start)
  //     .subscribe((location: BackgroundGeolocationResponse) => {
  //       console.log('BGS - Start Service');
  //       // this.toast('Géolocalisation démarré...');
  //     });
  //     this.backgroundGeolocation.on(BackgroundGeolocationEvents.stop)
  //     .subscribe((location: BackgroundGeolocationResponse) => {
  //       console.log('BGS - Stop Service');
  //       // this.toast('Fin géolocalisation...');
  //       // this.bgs.doSync();
  //     });


  //     this.backgroundGeolocation.on(BackgroundGeolocationEvents.error)
  //     .subscribe((error) => {
  //       console.log('BGS - ERREUR !!');
  //       console.log((error));
  //       console.log(JSON.stringify(error));
  //       // this.toast('Fin géolocalisation...');
  //       // this.bgs.doSync();
  //     });

  //     this.bgs.startBackgroundGeolocation(true);
  //     this.bgs.checkStatutAgent();
  //     this.bgs.configBackgroundGeolocInit = true;

  //   });
  // }

  async showToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async showLoadIab(){
    this.loadingIab = await this.loadingCtrl.create({
      message: 'Envoi de votre réponse, veuillez patienter...',
    });
    this.loadingIab.present();
  }

  async hideLoadIab(){
    this.loadingIab.dismiss();
  }
 
  async savePositionGps(){
    // this.showToast('Nous vérifions votre géolocalisation...');
    // await this.bgService.doSync();
    // this.bgService.backgroundGeolocation.getCurrentLocation().then((resp) => {
    //   const datas = {
    //     lat: parseFloat(resp.latitude.toString()),
    //     lng: parseFloat(resp.longitude.toString()),
    //     agentId: this.authenticationStateService.userValue?.candidat_id,
    //     isCheckGps: true
    //   };
    //   this.api.savePositionGpsToMongoDb(datas).subscribe((respApi) => {
    //     console.log('TEST RESP respApi', respApi);
    //   });
    // });

    this.geo.getCurrentPosition({ maximumAge: 3000, timeout: 5000, enableHighAccuracy: true }).then((resp) => {
      if(resp.coords.latitude && resp.coords.longitude){
         this.shared.hasLocationAccepted = true;
         this.bgService.startBackgroundGeolocation();

      }else{
        this.showToast('Veuillez activer votre localisation pour continuer à utliser Wanteez Agent');

        this.shared.hasLocationAccepted = false;

      }
      const datas = {
        lat: parseFloat(resp.coords.latitude.toString()),
        lng: parseFloat(resp.coords.longitude.toString()),
        agentId: this.authenticationStateService.userValue?.candidat_id,
        isCheckGps: true,
        timestamp: moment().format('x')
      };
      this.api.savePositionGpsToMongoDb(datas).subscribe((respApi) => {
        console.log('TEST RESP respApi', respApi);
        console.log('TEST RESP respApiString', JSON.stringify(respApi));

      });


    }).catch((error) => {
      console.error(error);
       this.bgService.stopBackgroundGeolocation();

      this.bgService.initConfig();
      this.bgService.startBackgroundGeolocation();
      this.shared.hasLocationAccepted = false;
    });
  }
 

configureBackgroundGeolocation() {
   // 1. Listen to events (see the docs a list of all available events)
  BackgroundGeolocation.onLocation(this.onLocation.bind(this));
  BackgroundGeolocation.onMotionChange(this.onMotionChange.bind(this));
  BackgroundGeolocation.onActivityChange(this.onActivityChange.bind(this));
  BackgroundGeolocation.onGeofence(this.onGeofence.bind(this));
  BackgroundGeolocation.onHttp(this.onHttp.bind(this));
  BackgroundGeolocation.onEnabledChange(this.onEnabledChange.bind(this));
  BackgroundGeolocation.onConnectivityChange(this.onConnectivityChange.bind(this));

  // 2. Configure the plugin
  BackgroundGeolocation.ready({
    debug: true,
    logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
    desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
    distanceFilter: 10,
    stopOnTerminate: false,
    startOnBoot: true,
    url: 'http://192.168.1.27:8000/api/saveLocationBackground',
    autoSync: true,
    params: {
      foo: 'bar'
    }
  }, (state) => {
    // Note:  the SDK persists its own state -- it will auto-start itself after being terminated
    // in the enabled-state when configured with stopOnTerminate: false.
    // - The #onEnabledChange event has fired.
    // - The #onConnectivityChange event has fired.
    // - The #onProviderChange has fired (so you can learn the current state of location-services).
    
    if (!state.enabled) {
 
      // 3. Start the plugin.  In practice, you won't actually be starting the plugin in the #ready callback
      // like this.  More likely, you'll respond to some app or UI which event triggers tracking.  "Starting an order"
      // or "beginning a workout", for example.
      BackgroundGeolocation.start();
    } else {       
     

      BackgroundGeolocation.start();
 
      // If configured with stopOnTerminate: false, the plugin has already begun tracking now.        
      // - The #onMotionChange location has been requested.  It will be arriving some time in the near future.        
    }
  });
}

onLocation(location:Location) {
  this.savePositionGps();
  console.log('[location] -', location);
}
onMotionChange(event:MotionChangeEvent) {
  this.savePositionGps();

  console.log('[motionchange] -', event.isMoving, event.location);
}
onActivityChange(event:MotionActivityEvent) {
  console.log('[activitychange] -', event.activity, event.confidence);
}
onGeofence(event:GeofenceEvent) {
  console.log('[geofence] -', event.action, event.identifier, event.location);
}
onHttp(event:HttpEvent) {
  console.log('[http] -', event.success, event.status, event.responseText);
}
onEnabledChange(enabled:boolean) {
  console.log('[enabledchange] - enabled? ', enabled);
}
onConnectivityChange(event:ConnectivityChangeEvent) {
  console.log('[connectivitychange] - connected?', event.connected);
}

 
  }

