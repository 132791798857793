import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noSecond'
})
export class NoSecondPipe implements PipeTransform {

  transform(heure: string): string{
    const time =  heure.split('-');
    const arr = time[0].split(':');
    const arr2 = time[1].split(':');
    return arr[0] + ':' + arr[1] + ' - ' + arr2[0] + ':' + arr2[1];
  }

}

export function noSecond(heure: string, char = ' - '): string{
  const time =  heure.split('-');
  const arr = time[0].split(':');
  const arr2 = time[1].split(':');
  return arr[0] + ':' + arr[1] + char + arr2[0] + ':' + arr2[1];
}
